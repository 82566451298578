import React, { useEffect, useState } from 'react'
import { throttle } from 'throttle-debounce'

import { UStoreProvider } from '@ustore/core'
import { t } from '$themelocalization'

import Slot from '../components/widgets/Slot'
import Layout from '../components/layout'
import CategoryItem from '../components/category/CategoryItem'
import ProductItem from '../components/products/ProductItem'
import PromotionItem from '../components/products/PromotionItem'
import { Slider, Gallery } from '$core-components'

import { getIsNGProduct } from '$themeservices/utils'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import theme from '$styles/_theme.scss'
import './Home.scss'

const Home = (props) => {
  const { customState: { categories } } = props

  const [isMobile, setIsMobile] = useState(false)
  const [promotionItemButtonUrl, setPromotionItemButtonUrl] = useState('')
  const [homeFeaturedProducts, setHomeFeaturedProducts] = useState(null)
  const [homeFeaturedCategory, setHomeFeaturedCategory] = useState(null)

  const promotionItemImageUrl = getVariableValue('--homepage-carousel-slide-1-image', require(`$assets/images/banner_image.png`), true)
  const promotionItemTitle = getVariableValue('--homepage-carousel-slide-1-main-text', t('PromotionItem.Title'))
  const promotionItemSubtitle = getVariableValue('--homepage-carousel-slide-1-sub-text', t('PromotionItem.Subtitle'))
  const promotionItemButtonText = getVariableValue('--homepage-carousel-slide-1-button-text', t('PromotionItem.Button_Text'))
  const onResize = () => setIsMobile(document.body.clientWidth < parseInt(theme.md.replace('px', '')))

  useEffect(() => {
    window.addEventListener('resize', onResize.bind(this))
    throttle(250, onResize);// Call this function once in 250ms only

    (async () => {
      const maxInPage = 200
      let { Count, Categories } = await UStoreProvider.api.categories.getTopCategories(1, maxInPage)
      if (Count === 0) {
        return
      }

      const page = Math.ceil(Count / maxInPage)
      if (page > 1) {
        const { Categories: lastPageCategories } = await UStoreProvider.api.categories.getTopCategories(page, maxInPage)
        if (lastPageCategories.length > 0) {
          Categories = lastPageCategories
        }
      }

      const homeFeaturedCategory = Categories[Categories.length - 1]
      const { Products } = await UStoreProvider.api.products.getProducts(homeFeaturedCategory.ID, 1)
      setHomeFeaturedProducts(Products)
      setHomeFeaturedCategory(homeFeaturedCategory)
    })()

    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
      clearCustomState()
    }
  }, [])

  useEffect(() => {
    if (categories && categories.length && !promotionItemButtonUrl) {
      const { FriendlyID, Name } = categories[0]
      const defaultURL = urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })
      setPromotionItemButtonUrl(getVariableValue('--homepage-carousel-slide-1-button-url', defaultURL, false, true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customState, categories])

  if (!props.state) {
    return null
  }

  return (
    <Layout {...props} className="home">
      <div className = "certification-info" >
        {t('Header.Certification')}
        {/* BEGIN - temporär eingeblendet*/}
        {/*<div style={{width: '100%', height: 'auto', backgroundColor: '#ffffff', marginTop: '1rem'}}>*/}
        {/*  <p style={{marginLeft: '10px'}}>*/}
        {/*    Unsere Türen bleiben vom <span style={{fontSize: '24px'}}>24. Dezember bis zum 6. Januar</span> geschlossen.<br />*/}
        {/*    Wir danken für Ihr Verständnis und wünschen Ihnen eine frohe und erholsame Weihnachtszeit!*/}
        {/*    <br />*/}
        {/*    <br />*/}
        {/*    Nos portes seront fermées du <span style={{fontSize: '24px'}}>24 décembre au 6 janvier.</span><br />*/}
        {/*    Merci de votre compréhension et nous vous souhaitons de joyeuses fêtes de fin d’année!*/}
        {/*  </p>*/}
        {/*</div>*/}
        {/* END - temporär eingeblendet*/}
      < /div>

      {/*<Slot name="homepage_top"/>*/}
      {/*<div className="promotion-wrapper">*/}
      {/*  /!*<Slider>*!/*/}
      {/*  /!*  <PromotionItem*!/*/}
      {/*  /!*    imageUrl={promotionItemImageUrl}*!/*/}
      {/*  /!*    title={promotionItemTitle}*!/*/}
      {/*  /!*    subTitle={promotionItemSubtitle}*!/*/}
      {/*  /!*    buttonText={promotionItemButtonText}*!/*/}
      {/*  /!*    url={promotionItemButtonUrl}*!/*/}
      {/*  /!*  />*!/*/}
      {/*  /!*</Slider>*!/*/}
      {/*  /!*<div>huhu</div>*!/*/}
      {/*</div>*/}

      <div className='info-section-wrapper'>
        {/* BEGIN - damit das obere tempäräre nicht diesen unteren Content überlagert, habe ich etwas mehr margin gegeben. */}
        <div className='middle-section' style={{"margin-top": "50px"}}>
        {/* --> ist für den Infobanner oben, siehe => BEGIN - temporär eingeblendet --> <div className='middle-section' style={{"margin-top": "220px"}}>*/}
          {/* END - temporär eingeblendet*/}
          <h1 style={{'line-height': '40px', "padding-top": "40px"}}>{t('Info.Section.Title')} <br/>
            <span style={{"font-size": "24px"}} className="fs-6">{t('Info.Section.Subtitle')}</span></h1>
          <h2 style={{"font-size": "16px", "color": "#919190"}}>{t('Info.Section.Text')}</h2>
          <h2>{t('Info.Section.Categories')}</h2>
          {categories && categories.length > 0 && <div className="categories-wrapper">
            {/*<Slider multi>*/}
            {categories.map((model) => {
              return <CategoryItem key={model.ID} model={model}
                                   url={urlGenerator.get({
                                     page: 'category', id: model.FriendlyID, name: decodeStringForURL(model.Name)
                                   })}/>
            })}
            {/*</Slider>*/}
          </div>}

          {/*<div className="divider"/>*/}
          {/*{homeFeaturedCategory && homeFeaturedProducts &&*/}
          {/*  <div className="featured-products-wrapper">*/}
          {/*    <Gallery title={homeFeaturedCategory.Name}*/}
          {/*             seeAllUrl={urlGenerator.get({*/}
          {/*               page: 'category',*/}
          {/*               id: homeFeaturedCategory.FriendlyID,*/}
          {/*               name: decodeStringForURL(homeFeaturedCategory.Name)*/}
          {/*             })}*/}
          {/*             gridRows="2">*/}
          {/*      {*/}
          {/*        homeFeaturedProducts.map((model) => {*/}
          {/*          const hideProduct =*/}
          {/*            isMobile &&*/}
          {/*            model.Attributes &&*/}
          {/*            model.Attributes.find(attr => attr.Name === 'UEditEnabled' && attr.Value === 'true') !== undefined*/}

          {/*          return !hideProduct &&*/}
          {/*            <ProductItem*/}
          {/*              key={model.ID}*/}
          {/*              model={model}*/}
          {/*              productNameLines="2"*/}
          {/*              descriptionLines="4"*/}
          {/*              url={getIsNGProduct(model)*/}
          {/*                ? urlGenerator.get({*/}
          {/*                  page: 'products',*/}
          {/*                  id: model.FriendlyID,*/}
          {/*                  name: decodeStringForURL(model.Name)*/}
          {/*                })*/}
          {/*                : urlGenerator.get({*/}
          {/*                  page: 'product',*/}
          {/*                  id: model.FriendlyID,*/}
          {/*                  name: decodeStringForURL(model.Name)*/}
          {/*                })*/}
          {/*              }*/}
          {/*            />*/}
          {/*        })*/}
          {/*      }*/}
          {/*    </Gallery>*/}
          {/*  </div>*/}
          {/*}*/}
        </div>
        <div className='info-section1'>
          <h2>{t('Info.Section.1.Title')}</h2>
          <p>{t('Info.Section.1.Text.1')}</p>
          <p>{t('Info.Section.1.Text.2')}</p>
          <div className='video-container'>
          </div>
        </div>
      </div>
      <div className='info-section-wrapper'>
        <div className='info-section2'>
          <iframe className='iframe' id='c2cVideo' src='https://www.youtube.com/embed/ZC9mNGUORR0?enablejsapi=1&origin=https%3A%2F%2Fpureprint.ch' />
          <img id='kreislauf' src='https://shop.voegeli.ch/uStore/images/_eigene_Skins/pureprint_shop/img/Kreislauf_DE.png' />
        </div>
      </div>
      {/* <!-- Start of HubSpot Embed Code --> */}
      <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/25288491.js" />
      {/* <!-- End of HubSpot Embed Code --> */}
      <Slot name="homepage_bottom"/>
    </Layout>
  )

}

export default Home

function clearCustomState () {
  UStoreProvider.state.customState.delete('homeFeaturedProducts')
  UStoreProvider.state.customState.delete('homeFeaturedCategory')
  UStoreProvider.state.customState.delete('currentProduct')
  UStoreProvider.state.customState.delete('currentOrderItem')
  UStoreProvider.state.customState.delete('currentOrderItemId')
  UStoreProvider.state.customState.delete('currentOrderItemPriceModel')
  UStoreProvider.state.customState.delete('lastOrder')
  UStoreProvider.state.customState.delete('currentProductThumbnails')
}
